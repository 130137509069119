.video-player {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    z-index:-1;
  }

  .video-container{
    position: absolute;
    width:100%;
    height:100%;
    overflow:hidden;
    z-index: -1;
  }
  
  @media screen and (max-width: 767px) {
    .video-player {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }
  