* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}

body {
  background-color: #333;
  color: #eee;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
  overflow: hidden;
}

.App {
  text-align: center;
  position: fixed;
  width:100%;
  height:100%;
  transition:opacity 1.5s ease-in-out;
}

.App.inactive{
  pointer-events:none;
  opacity:0;
}



.pointer-all{
  pointer-events: all;
}

.App .logo{
width:80%;
}

.input-container{
  width: 100%;
  font-family: 'Louize';
  font-size:23pt;
  text-transform: uppercase;
  letter-spacing:.1em;
  margin-right:-1em;
  position: absolute;
  bottom:10%;
  pointer-events: none;
  overflow:hidden;
}




.mask{
  width: 100%;
  padding-top:70px;
  padding-bottom:70px;
  margin-bottom:-70px;
}


.backdrop{
  width: 440px;
  max-width:100vw;
  height: 25%;
  border-radius: 50%;
  background: #000000d1;
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  z-index: 1;
  filter: blur(43px);
}

.instructions{
  z-index: 2;
  position: relative;
}


.input-container.active .mask {
  transition: opacity 1s  ease-out;
  opacity: 1;
}
.input-container.inactive .mask{
  transition: opacity 1s  ease-out;
  opacity: 0;

  pointer-events: none;
}

.intention{
  margin-top:10px;
  font-size:23pt;
}

.custom-input{
  margin-top:30px;
  font-size:23pt;
  text-align: center;
  background: transparent;
  -webkit-appearance: none;
  border: initial;
  font-family: 'Louize';
  color: white;
  max-width:50%;
  pointer-events: all;
  position: relative;
  z-index: 3;
}

.underline{
/* position: absolute; */
margin-top:8px;
width:0%;
height:2px;
background:white;
left:50%;
margin-left: auto;
margin-right: auto;
animation-name: expand;
animation-duration: 1s;
animation-timing-function: ease-out;
animation-iteration-count: 1;
animation-fill-mode: forwards; 
animation-delay:.5s;
max-width: 50vw;
}

.custom-input:focus {outline:none;}

.custom-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: .5; /* Firefox */
}
.anna-title{
  font-family: 'Louize';
  font-size:23pt;
  text-transform: uppercase;
  letter-spacing:.3em;
  margin-right:-0.3em;
  position: relative;
  z-index: 3;
}
.App-header {
  background-color: rgba(0, 0, 0, 1);
position: relative;
height: 100%;
width: 100%; 
z-index: 33
;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  animation-name: background-fade;
  animation-duration: 3.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
  animation-delay:1s;
}



.login-contents{
  opacity: 0;
  -webkit-mask-image: linear-gradient(to left, #0000 25%, #000 45%); 
  -webkit-mask-size: 500%;

  animation-name: text-wipe;
  animation-duration: 5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
  animation-delay:2s;
}


.hidden {
  display: none;
}


.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  /* filter: blur(8em) opacity(0.6); */
  position: absolute;
}

.ui-overlay{
  pointer-events: none;
}

.main-wrapper {
  margin: 0 auto;
  justify-content: left;
  text-align: center;
  position: absolute;
  top:0%;
  left:50%;
  transform:translateX(-50%);
  padding-top:2%;
  padding-left:10%;
  padding-right:10%;
  filter: drop-shadow(5px 5px 18px #020f2a0d);
  pointer-events: none;
  width: 100%;

}

.container {
  align-items: center;
  /* display: flex; */
  height: 100%;
}

.main-container {
  flex: 1;
}

.now-playing__name {
  font-size: 1.5em;
  margin-bottom:1%;
  font-family: 'Louize';
  text-transform: uppercase;
  font-size:15pt;
  letter-spacing:.3em;
margin-left: auto;
margin-right: auto;
  width:100%;
  max-width: 600px;
}

.now-playing__artist {
  margin-bottom: 1em;
  font-family: 'Louize';

}

.now-playing__status {
  margin-bottom: 1em;
}

.now-playing__cover {
  text-align: right;
  width: 120px;
  height: 120px;
  filter: drop-shadow(5px 5px 18px #0000005d);
  pointer-events: all;
}

.now-playing__side {
  margin-top:20px;
  
}

.btn-spotify {
  margin-top:20px;
  background-color:white;
  border-radius:28px;
  display:inline-block;
  cursor:pointer;
  color:black;
  font-family:Helvetica, sans-serif;
  font-size:17px;
  padding:16px 31px;
  text-decoration:none;
  transition:transform .3s ease-in-out,width .3s ease-in-out,opacity .3s ease-in-out;
  width: fit-content;
  text-transform: uppercase;
  padding-left: 60px;
  padding-right: 60px;
}
.btn-spotify.disabled{
  opacity:0.5;
}

.spotify-icon{
  position: absolute;
  display: block;
  left:calc(50% - 100px);
  transform: translate(-50%, 0%);
  margin-bottom:10px;
  width:40px;
  top:20%;
}

.btn {
  margin-top:20px;
  background-color:white;
  border-radius:28px;
  display:inline-block;
  cursor:pointer;
  color:black;
  font-family:Helvetica, sans-serif;
  font-size:17px;
  padding:16px 31px;
  text-decoration:none;
  transition:transform .3s ease-in-out,width .3s ease-in-out;

}

.btn-spotify:active:not(.disabled) {
  position:relative;
  top:1px;
}




.btn-spotify:hover {
/* width:38%; */
}



img { background: transparent; }


/* canvas{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0.5);
} */

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.active{
  opacity:1;
}

@font-face {
  font-family: 'Louize';
  src:  url('./Louize-Regular.ttf') format('truetype'),
}

.align-centre{
  display: block;
  margin-left:auto;
  margin-right:auto;
}

.control{
  margin-top:15px;
  height:40px;
}

.share{
  padding-top:10%;
  position: absolute;
  left:50%;
  transform: translateX(-50%);
opacity: 0;


  animation-name: fade-translate;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
}


.animation-text-wipe {
  opacity: 0;
}

.animation-text-wipe.animate-in {
  animation-name: fade-translate;
  animation-duration: 3.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
}


.share-canvas{
  max-width:70vw;
  max-height:70vw;
  width:400px;
  height:400px;
  border-radius:8px;
  margin-left:auto;
  margin-right:auto;
  display:block;
  margin-top:30%;
}

.share-screen{
  position: fixed;
  width:100%;
  height:100%;
  /* background-color: #00000033; */
  z-index: 1;

  animation-name: background-blur;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
}
.share-button{
  position: absolute;
  right: 10px;
  top: 10px;
  /* text-decoration: underline; */
  /* text-underline-offset: 7px; */
  font-weight: bold;
  font-size: 16pt;
  background-color: rgba(0, 0, 0, 0.202);
  width:60px;
  height:60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;
  transition: background-color .3s ease-in-out,opacity .3s ease-in-out;
}

.share-button.inactive{
  pointer-events:none;
  opacity:0;
}

.share-button:hover{
  background-color: rgba(0, 0, 0, 0.402);
}



.video-player {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .video-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
  }
}



@keyframes text-wipe-translate {
    0% {
        opacity: 1;
        transform:translateX(-50%) scale(0.9);
        -webkit-mask-position: 100%;
    }

    60%{
      -webkit-mask-position: 40%;
      transform:translateX(-50%) scale(1);
    }

    100% {
        opacity: 1;
        
        -webkit-mask-position: 0%; 
    }
}

@keyframes fade-translate {
  0% {
      opacity: 0;
      transform:translateX(-50%) scale(0.9);
  }

  60%{
    transform:translateX(-50%) scale(1);
  }

  100% {
      opacity: 1;
  }
}

@keyframes fade {
  0% {
      opacity: 0;
      transform:scale(0.9);
  }

  60%{
    transform: scale(1);
  }

  100% {
      opacity: 1;
  }
}

@keyframes text-wipe {
  0% {
      opacity: 1;
      transform:scale(0.9);
      -webkit-mask-position: 100%;
  }

  60%{
    -webkit-mask-position: 40%;
    transform: scale(1);
  }

  100% {
      opacity: 1;
      
      -webkit-mask-position: 0%; 
  }
}


@keyframes background-fade {
  0% {
    background: rgba(0, 0, 0, 1);
  }

  100% {
    background: rgba(0, 0, 0, 0.328);
  }
}

@keyframes background-blur {
  0% {
    /* backdrop-filter: blur(0px); */
    background-color: #00000000;
  }

  100% {
    /* backdrop-filter: blur(10px); */
    background-color: #00000080;
  }
}

@keyframes expand {
  0% {
    /* backdrop-filter: blur(0px); */
    opacity: 0;
    width: 0%;
  }

  100% {
    /* backdrop-filter: blur(10px); */
    opacity: 1;
    width: 320px; 
  }
}






@media screen and (min-width: 768px) {

  .spotify-icon{
    left:calc(50% + 120px);
    top:74px;
  }
  
  /* Select the logo element and set the maximum width to 500px */
  .logo {
    max-width: 700px;
  }

  /* Select the Anna title element and increase the font size to 30pt */
  .anna-title {
    font-size: 35pt;
  }
  .now-playing__side {
    font-size: 15pt;
  }

  .now-playing__name {
    font-size: 20pt;
  }

  .mask{
    padding:110px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -100px;
    width: fit-content;
  }
  
  
}